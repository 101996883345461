/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import Bonus from '~molecules/bonus-box/template-four';
import StarRating from '~molecules/star-rating/one-star';
import OperatorCta from '~atoms/button/operator-cta';
import { prettyTracker, imagePrettyUrl, getAltText, getExtraField } from '~helpers/getters';
import LazyImage from '~hooks/lazy-image';

import styles from './default-row.module.scss';

const Row = ({
  item,
  oneliner = 'main',
  layout = 'list',
  tracker = 'main',
  pageTemplate,
  itemRefs,
  index,
  className,
}) => {
  const prettyLink = prettyTracker(item, 'main', false, pageTemplate);
  const itemRating = item.rating;

  const imageObject = item?.logo_url_object;
  const reviews = getExtraField(item?.extra_fields, 'reviews');
  const color = getExtraField(item?.extra_fields, 'color');

  const addToRefs = (el, itemNum) => {
    if (el && itemRefs) {
      itemRefs.current[itemNum] = el;
    }
  };

  return (
    <li
      className={`${className} ${styles.row} ${styles[layout]}`}
      ref={(el) => addToRefs(el, index)}
    >
      <div className={styles.ribbons}>
        {item.ribbons && item.ribbons.map((ribbon) => <span>{ribbon}</span>)}
      </div>
      <a
        href={prettyLink}
        title={item.name}
        className={`casinos-top-list-gtm logo-cta ${styles.logo}`}
        target="_blank"
        rel="noreferrer"
        style={{ backgroundColor: color }}
        aria-label={`${item.name} Link`}
      >
        <LazyImage
          alt={getAltText(imageObject, item.name)}
          src={imagePrettyUrl(imageObject?.filename || item?.logo_url, 100, 100)}
          width={100}
          height={100}
        />
      </a>
      <a
        href={prettyLink}
        target="_blank"
        rel="noreferrer"
        className={`toplist-variant-one-gtm bonus-cta ${styles.bonusCta}`}
      >
        <Bonus item={item} tracker={oneliner} />
      </a>
      <div className={styles.ratingReviews}>
        <StarRating numOfStars={5} rating={itemRating} />
        <span className={styles.reviews}>{reviews} Anmeldelser</span>
      </div>

      <div className={styles.ctaArea}>
        <OperatorCta
          playText="Få Bonus"
          operator={item}
          tracker={tracker}
          gtmClass="toplist-operator-cta-gtm "
          pageTemplate={pageTemplate}
          module="top_list"
        />
        <a className={styles.ctaText} href={prettyLink} target="_blank" rel="noopener noreferrer">
          Besøg {item.name}
        </a>
      </div>
    </li>
  );
};

Row.propTypes = {
  oneliner: PropTypes.string,
  layout: PropTypes.string,
  tracker: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    ribbons: PropTypes.arrayOf(PropTypes.string),
    logo_url: PropTypes.string,
    selling_points: PropTypes.arrayOf(PropTypes.string),
    review_link: PropTypes.string,
    rating: PropTypes.string,
    one_liners: PropTypes.shape({
      main: PropTypes.shape({
        one_liner: PropTypes.string,
      }),
    }),
    logo_url_object: PropTypes.shape({
      filename: PropTypes.string,
    }),
    standardised_logo_url_object: PropTypes.shape({
      filename: PropTypes.string,
    }),
    bonus: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  }).isRequired,
  pageTemplate: PropTypes.string,
};

export default Row;
